<template>
  <div class="container mt-4">
    <div class="flex">
      <router-link to="/stats" class="text-blue-500">
        <i class="fas fa-chevron-left font-semibold"></i>
        {{ $t("back") }}
      </router-link>
      <div v-if="timeslot" class="flex ml-10">
        <p class="font-semibold mr-2">{{ $t('stats_for')}}</p>
        {{ timeslot.user_name }}
      </div>
    </div>
    <table v-if="timeslot" class="text-xs text-left w-full mt-4 border-collapse border border-gray-300">
      <thead class="bg-gray-300">
        <th class="w-1/3 p-2"> {{ $t('planned_on') }}</th>
        <th class="w-1/5 3-2"> {{ $t('planned_for') }}</th>
        <th class="w-1/5 3-2"> {{ $t('hours_before') }}</th>
      </thead>
      <tbody>
        <tr v-for="(detail, index) in timeslot.details" :key="index"
          class="text-center">
          <td class="p-2 border border-gray-300"> {{ detail.created_at }} </td>
          <td class="p-2 border border-gray-300">{{ detail.start }}</td>
          <td class="p-2 border border-gray-300">{{ detail.last_minute }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getTimeSlotStatDetailByUserId } from "@/services/stat-service";
  export default {
    data: () => ({
      timeslot: null,
    }),
    async mounted() {
      this.$emit('setLoading', true);
      try {
        const { data } = await getTimeSlotStatDetailByUserId(this.$route.params.userId);
        this.timeslot = data;
      } catch (error) {
        console.error('DEBUG" timeSlotDetail mounted', error);
      }
      this.$emit('setLoading', false);
    }
  }
</script>

<style lang="scss" scoped>

</style>
